














import { Component, Vue } from "vue-property-decorator";

import BlogPostAppModel from "@/api/blog_post.app.model";

import PostsCarousel from "@/layouts/components/blog/user/PostsCarousel.vue";

import { BlogPost, IdealMktCategories } from "@/interfaces/blog_post";
import { removeDiacritics } from "@/helpers";
import { inflateEntity } from "@/api/_request";

interface PostsByCategoryProps {
	id: string;
	data: BlogPost[];
	sectionTitle: string;
	noTitle?: boolean;
}

@Component({ components: { PostsCarousel } })
export default class BlogPostsTheme extends Vue {
	blogPostModel = BlogPostAppModel;

	posts: BlogPost[] = [];

	get postsByCategory(): PostsByCategoryProps[] {
		const subcategories = new Set<string>();

		this.posts.forEach(post => {
			if (post.subcategory?.name) {
				subcategories.add(post.subcategory.name);
			}
		});

		return [IdealMktCategories.THEMES, ...subcategories]
			.map((category, index) => ({
				id: "carousel-" + index,
				data: this.posts.filter(post => {
					const categoryKey = post.subcategory?.name ? "subcategory" : "mainCategory";

					return removeDiacritics(post[categoryKey]?.name || "") === removeDiacritics(category);
				}),
				sectionTitle: category,
				noTitle: category === IdealMktCategories.THEMES,
			}))
			.filter(posts => posts.data.length);
	}

	mounted() {
		this.loadPosts();
	}

	async loadPosts() {
		this.$store.dispatch("app/showLoading");

		try {
			const posts = (await this.blogPostModel.search({
				join: [{ field: "mainCategory" }, { field: "subcategory" }],
				filter: { field: "mainCategory.name", operator: "$eq", value: IdealMktCategories.THEMES },
			})) as BlogPost[];

			this.posts = posts.map(post => inflateEntity(post) as BlogPost);
		} catch (error) {
			console.error(error);
		}

		this.$store.dispatch("app/hideLoading");
	}
}
